<!-- qa搜索模块 -->
<!-- 引入这个模块需要有pagedata -->
<template>
  <div class="searchModelBody">
    <div class="searchBox">
      <h1>{{ $fanyi("RAKUMART帮助中心") }}</h1>
      <div class="searchBoxCon">
        <van-popover v-model:show="visible" class="qaSearchBox">
          <ul class="searchHistory">
            <li
              v-for="(item, index) in showSearchList"
              :key="index"
              @click="
                searchStr = item.text;
                visible = false;
                toResultsPage();
              "
            >
              {{ item.text }}
            </li>
          </ul>
          <template #reference>
            <input
              class="qaSearchInput"
              v-model="searchStr"
              type="text"
              @input="piPei"
              @keydown.enter.prevent="toResultsPage"
              :placeholder="$fanyi('请输入你的问题或者关键字')"
            />
          </template>
        </van-popover>

        <div
          v-if="!!searchStr"
          @click.prevent="searchStr = ''"
          class="clearInput"
        >
          <img src="h../../../../assets/centro-de-ayuda/cha.png" alt="" />
        </div>

        <button title="Buscar" @click="toResultsPage">
          <img
            src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/648c05b991201.png"
            alt=""
          />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { Popover } from "vant";
export default {
  data() {
    return {
      searchStr: "",
      visible: false, //是否显示搜索列表弹出框
      showSearchList: [],
    };
  },
  components: {
    [Popover.name]: Popover,
  },
  computed: {
    // 为了避免重复引入pageData使用父组件里的pagedata
    pageData() {
      return this.$parent.pageData;
    },
  },
  watch: {
    $route: {
      handler(val, oldVal) {
        if (this.$route.query.s) {
          this.searchStr = this.$route.query.s;
          this.toResultsPage();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    // if (this.$store.state.QASearchHistore.length) {
    //   this.searchStr = this.$store.state.QASearchHistore[0].text;
    //   this.showSearchList = this.$store.state.QASearchHistore.slice(0, 6);
    // }
    // 假如路由带有s参数代表在搜索页面,显示正在搜索的关键词
    if (this.$route.query.s) {
      this.searchStr = this.$route.query.s.replace("+", " ");
    }
  },
  methods: {
    // 输入字符时匹配搜索历史
    piPei() {
      this.showSearchList = [];
      this.$store.state.QASearchHistore.forEach((element) => {
        if (element.text.indexOf(this.searchStr.trim()) != -1) {
          this.showSearchList.unshift(element);
        }
      });
      this.showSearchList = this.showSearchList.slice(0, 6);
      this.visible = false;
      setTimeout(() => {
        if (this.showSearchList.length) {
          this.visible = true;
        }
      }, 100);
    },
    // 跳转搜索结果页面
    toResultsPage() {
      // 如果没有重复的就存起来
      if (
        (this.$store.state.QASearchHistore,
        !this.$store.state.QASearchHistore.find((item) => {
          return item.text == this.searchStr.trim();
        }))
      ) {
        // 把搜索的词语存起来给搜索历史模块使用
        this.$store.commit(
          "getQASearchHistore",
          this.$store.state.QASearchHistore.unshift({
            text: this.searchStr.trim(),
          })
        );
      }
      this.$toast.loading({
        duration: 0,
        message: this.$fanyi("加载中..."),
        forbidClick: true,
        loadingType: "spinner",
      });
      let jumpResults = "resultado-busqueda-no-encontrado";
      this.$api
        .articleList({
          keyword: this.searchStr.trim(),
        })
        .then((res) => {
          this.$toast.clear();
          ////console.log('事件名',res)
          if (res.code != 0)
            return this.$message.error(this.$fanyi(res.data.msg));
          this.$store.commit("setsearchList", this.chulishuju(res.data));
          if (res.data.length) {
            jumpResults = "resultado-de-busqueda";
          }
          this.$fun.routerToPage(
            "/" +
              jumpResults +
              "?s=" +
              encodeURIComponent(this.searchStr.trim())
          );
        });

      // let jumpResults = "resultado-busqueda-no-encontrado";
      // searchArr.forEach((strArr) => {
      //   if (
      //     this.pageData.findIndex((item) => {
      //       return (
      //         (item.title.indexOf(strArr) != -1 ||
      //           // item.answer.indexOf(strArr) != -1 ||
      //           item.content.indexOf(strArr) != -1) &&
      //         item.menuLevel != 1
      //       ); //利用qa首页的menuLevel把qa首页排除出搜索结果
      //     }) != -1
      //   ) {
      //
      //   }
      // });
      // this.$fun.routerToPage(
      //   "/" + jumpResults + "?s=" + encodeURIComponent(searchStrJia)
      // );
    },
    // 处理数据
    chulishuju(inData) {
      let pageData = [];
      inData.forEach((onel) => {
        pageData.push({
          pageName: onel.name,
          router: onel.id,
          menuLevel: 2,
          menu: onel.name,
          image: onel.image,
          title: onel.name,
          content: (() => {
            let onelCon = [];
            onel.qa_article.forEach((onelConItem) => {
              onelCon.push({
                routerName: `${onelConItem.category_id}-${onelConItem.id}`,
                title: onelConItem.title,
              });
            });
            return onelCon;
          })(),
        });
        onel.qa_article.forEach((twel) => {
          pageData.push({
            pageName: twel.title,
            router: `${twel.category_id}-${twel.id}`,
            title: twel.title,
            content: twel.content,
            menu: onel.name,
            menuRouter: onel.id,
            menuLevel: 3,
          });
        });
      });
      // console.log(pageData);
      return pageData;
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.searchModelBody {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f6f7;
  .searchBox {
    h1 {
      text-align: center;
      margin: 80px 0 40px;
      font-size: 36px;
      line-height: 54px;
      font-weight: bold;
    }
    .searchBoxCon {
      position: relative;
      margin-bottom: 80px;
      .qaSearchInput {
        background-color: white;
        width: 690px;
        height: 90px;
        border-radius: 45px;
        border: none;
        padding: 0 86px 0 32px;
        font-size: 24px;
        transform: 0.4s;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
        &:focus {
          box-shadow: 0px 4px 20px -1px rgba(0, 0, 0, 0.14);
        }
      }
      .clearInput {
        position: absolute;
        right: 100px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        img {
          width: 15px;
          height: 15px;
        }
      }
      button {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        padding: 0 34px;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        img {
          width: 34px;
          height: 34px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.qaSearchBox {
  border-radius: 0px;
  .van-popover__content {
    border-radius: 6px;
  }
  .searchHistory {
    display: flex;
    flex-direction: column;
    li {
      min-width: 300px;
      max-width: 600px;
      line-height: 60px;
      font-size: 24px;
      padding: 0 20px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:active {
        background-color: #b4b4b4;
      }
    }
  }
}
</style>
