<!-- rakumart QA -->
<template>
  <div>
    <ohead />
    <div class="pageBody">
      <searchModel />
      <div class="don">
        <!-- <div v-html="showpage.title"></div>
        <div v-html="showpage.answer"></div> -->
        <template v-if="showpage.menuLevel == 1">
          <!-- <div id="dd"></div> -->
          <div class="centro-de-ayuda-page">
            <div class="row">
              <div class="popularAnswers">
                <h1>よくある質問について:</h1>
                <p
                  v-for="(hotItem, hotIndex) in hotQaList"
                  :key="hotIndex"
                  name="myClick"
                  @click="
                    $fun.routerToPage(
                      `/questionAndAnswer?page=${hotItem.router}`
                    )
                  "
                >
                  {{ hotItem.title }}
                </p>
              </div>
            </div>
            <div class="row">
              <div
                v-for="(item, index) in jiekouData"
                :key="index"
                class="categorizedList"
                name="myClick"
                @click="$fun.routerToPage(`/questionAndAnswer?page=${item.id}`)"
              >
                <img :src="item.image" alt="" />
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
        </template>
        <template v-if="showpage.menuLevel == 2">
          <!-- <div v-html="showpage.content"></div> -->
          <div class="secconBox">
            <div class="titleBox">
              <span
                class="pageNameSpan"
                @click="$fun.routerToPage('/questionAndAnswer')"
                >{{ $fanyi("帮助中心") }}</span
              >
              <van-icon name="arrow" />
              <span class="redFont">{{ showpage.menu }}</span>
            </div>
            <div class="BodyBox">
              <div class="rightBox">
                <div class="showBodyBoxTitle">
                  {{ showpage.title }}
                </div>
                <hr />
                <ul class="pageListLink">
                  <li
                    v-for="(item, index) in showpage.content"
                    :key="index"
                    name="myClick"
                    @click="
                      $fun.routerToPage(
                        '/questionAndAnswer?page=' + item.routerName
                      )
                    "
                  >
                    {{ item.title }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
        <template v-if="showpage.menuLevel == 3">
          <div class="secconBox">
            <div class="titleBox">
              <span
                class="pageNameSpan"
                @click="$fun.routerToPage('/questionAndAnswer')"
                >{{ $fanyi("帮助中心") }}</span
              >
              <van-icon name="arrow" />
              <span
                class="pageNameSpan"
                @click="
                  $fun.routerToPage(
                    `/questionAndAnswer?page=${showpage.menuRouter}`
                  )
                "
                >{{ showpage.menu }}</span
              >
              <van-icon name="arrow" />
              <span class="redFont">{{ showpage.pageName }}</span>
            </div>
            <div class="BodyBox">
              <div class="rightBox thred">
                <div v-html="showpage.title"></div>
                <hr />
                <!-- class="listFont numStyle" allFontPage -->
                <div v-html="showpage.content"></div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- <contactUs /> -->
    <ofooter />
  </div>
</template>
<script>
import ohead from "@/components/headBar/index.vue";
import searchModel from "./components/searchModel.vue";
import ofooter from "@/components/footBar/index.vue";
import leftMenu from "./components/leftMenu.vue";
// import contactUs from "./components/contactUs.vue";
// var component = new MyComponent().$mount("#dd");
export default {
  data() {
    return {
      jiekouData: [],
      jiekouChuLiData: [],
      hotQaList: [],
    };
  },
  components: {
    ohead,
    searchModel,
    // contactUs,
    ofooter,
    leftMenu,
  },
  computed: {
    showpage() {
      return (
        this.jiekouChuLiData.find((item) => {
          return item.router == (this.$route.query.page || "questionAndAnswer");
        }) || { menuLevel: 1 }
      );
    },
  },
  created() {},
  mounted() {
    // this.xuanranshijian();
    this.getData();
  },
  // watch: {
  //   $route: {
  //     handler(val, oldVal) {
  //       console.log(val);
  //       setTimeout(() => {
  //         this.xuanranshijian();
  //       }, 300);
  //     },
  //     // 深度观察监听
  //     deep: true,
  //   },
  // },
  methods: {
    getData() {
      this.$toast.loading({
        duration: 0,
        message: this.$fanyi("加载中..."),
        forbidClick: true,
        loadingType: "spinner",
      });
      this.jiekouChuLiData = [];
      let allLoad = 0;
      this.$api.articleList().then((res) => {
        ////console.log('事件名',res)
        allLoad++;
        if (allLoad == 2) {
          this.$toast.clear();
        }
        if (res.code != 0)
          return this.$message.error(this.$fanyi(res.data.msg));
        this.jiekouData = res.data;
        //接下来的操作
        this.chulishuju();
      });
      this.$api.top10Articles().then((res) => {
        ////console.log('事件名',res)
        allLoad++;
        if (allLoad == 2) {
          this.$toast.clear();
        }
        if (res.code != 0)
          return this.$message.error(this.$fanyi(res.data.msg));

        //接下来的操作
        this.hotDataChuLi(res.data);
      });
    },
    hotDataChuLi(indata) {
      this.hotQaList = [];
      indata.forEach((onel) => {
        let pushData = {
          pageName: onel.title,
          router: `${onel.category_id}-${onel.id}`,
          title: onel.title,
          content: onel.content,
          menu: "最も多くの人が尋ねる",
          menuRouter: onel.category_id,
          menuLevel: 3,
        };
        this.jiekouChuLiData.push(pushData);
        this.hotQaList.push(pushData);
      });
    },
    chulishuju() {
      this.jiekouData.forEach((onel) => {
        this.jiekouChuLiData.push({
          pageName: onel.name,
          router: onel.id,
          menuLevel: 2,
          menu: onel.name,
          image: onel.image,
          title: onel.name,
          content: (() => {
            let onelCon = [];
            onel.qa_article.forEach((onelConItem) => {
              onelCon.push({
                routerName: `${onelConItem.category_id}-${onelConItem.id}`,
                title: onelConItem.title,
              });
            });
            return onelCon;
          })(),
        });
        onel.qa_article.forEach((twel) => {
          this.jiekouChuLiData.push({
            pageName: twel.title,
            router: `${twel.category_id}-${twel.id}`,
            title: twel.title,
            content: twel.content,
            menu: onel.name,
            menuRouter: onel.id,
            menuLevel: 3,
          });
        });
      });
    },
    // xuanranshijian() {
    //   let divArr = document.getElementsByName("myClick");
    //   divArr.forEach((divItem) => {
    //     divItem.addEventListener("click", () => {
    //       let _this = this;
    //       return eval("_this." + divItem.getAttribute("@click"));
    //     });
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
@import "./css/pagecss.scss";
.redFont {
  color: #b4272b;
}
.pageBody {
  .don {
    background-color: white;
    padding-bottom: 1px;
    .secconBox {
      margin: 0 auto;
      .titleBox {
        padding: 50px 30px 40px;
        width: max-content;
        // min-height: 185px;
        * {
          font-size: 20px;
          line-height: 30px;
        }
        .pageNameSpan {
          color: #7a7a7a;
        }
        .van-icon-arrow {
          margin: 0 20px;
          color: #b4272b;
        }
      }
      .BodyBox {
        margin: 0px 0 80px;
        display: flex;
        align-items: flex-start;
        hr {
          margin: 30px 0 50px;
          background-color: #d7d7d7;
          height: 1px;
          width: 100%;
        }
        .dianzi {
          height: 14px;
        }
      }
      .rightBox {
        flex: 1;
        padding: 0 30px;
      }
      .rightBox.thred {
        max-width: 738px;
      }
      /deep/.showBodyBoxTitle {
        font-size: 32px;
        font-weight: bold;
        line-height: 48px;
        margin-bottom: 30px;
      }

      /deep/.titleFontThree {
      }
    }
  }
}
</style>
